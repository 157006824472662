import { Configuration } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "7672259a-11b6-44f0-bfe6-274a5608e112", // Application (client) ID
    // authority: "https://login.microsoftonline.com/common", // Directory (tenant) ID
    //redirectUri: 'https://lemon-flower-01cbc1c10.4.azurestaticapps.net/'
    // redirectUri: 'http://localhost:3000/'
    redirectUri: `${process.env.PUBLIC_URL}/`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false, // Set to true for Internet Explorer 11
  },
};

// Add scopes
export const loginRequest = {
  scopes: ["User.Read", "api://itsavvyllc.onmicrosoft.com/cspAPI/api-scope"],
};
