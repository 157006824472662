import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Button,
  CssBaseline,
  Container,
  TextField,
  Select,
  MenuItem,
  Paper,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import Cart from "./cart"; // Import the Cart component
import "./cart.css"; // Import the Cart CSS for styling
import "./styles.css"; // Import the Cart CSS for styling
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import SignInButton from "./signIn";
import ProductDetailsModal from "./ProductDetailsModal";
import ItsLoadingSpinner from "./ItsLoadingSpinner";
import SavedOpportunities from "./SavedOpportunities";
import "./savedOpportunities.css";
import AccountNumberModal from "./AccountNumberModal";

const pca = new PublicClientApplication(msalConfig);
console.log("env", process.env);

const SearchTextField = ({ onFinish }) => {
  const [searchInput, setSearchInput] = useState(""); // State to hold the input value
  return (
    <TextField
      variant="outlined"
      value={searchInput} // Use searchInput to control the TextField
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onFinish(searchInput); // Call onFinish when Enter is pressed
        }
      }}
      onChange={(e) => setSearchInput(e.target.value)} // Update searchInput on each keystroke
      onBlur={() => onFinish(searchInput)} // Update commitSearch when TextField loses focus
      placeholder="Search…"
      style={{ marginBottom: "20px", marginRight: "8px" }}
    />
  );
};

const AuthenticatedApp = () => {
  const isAuthenticated = useIsAuthenticated();
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [termFilter, setTermFilter] = useState("");
  const [bcFilter, setbcFilter] = useState("");
  const [segFilter, setsegFilter] = useState("");
  const [skuTypeFilter, setskuTypeFilter] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [email, setEmail] = useState("");
  const [isSendingCart, setIsSendingCart] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");
  const [modalProduct, setModalProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [opportunityName, setOpportunityName] = useState(""); // State to store opportunity name
  const [savedOpportunities, setSavedOpportunities] = useState([]); // State to store saved opportunities
  const [showSavedOpportunities, setShowSavedOpportunities] = useState(false);
  const [termOptions, setTermOptions] = useState([]);
  const [bcOptions, setBcOptions] = useState([]);
  const [segOptions, setSegOptions] = useState([]);
  const [skuTypeOptions, setSkuTypeOptions] = useState([]);
  const [searchInput, setSearchInput] = useState(""); // This holds the input as the user types
  const [suggestions, setSuggestions] = useState([]);
  const [commitSearch, setCommitSearch] = useState(""); // This is what the DataGrid will use to filter

  const [isAccountNumberModalOpen, setIsAccountNumberModalOpen] =
    useState(false);

  console.log(showSavedOpportunities);

  // Rest of your code remains the same...

  // State and other variables remain the same...

  const getUniqueValues = (data, field) => {
    return [...new Set(data.map((item) => item[field]))].sort();
  };

  const clearFilters = () => {
    setSearchText("");
    setTermFilter("");
    setbcFilter("");
    setsegFilter("");
    setskuTypeFilter("");
  };

  const clearTermFilter = () => setTermFilter("");
  const clearBcFilter = () => setbcFilter("");
  const clearSegFilter = () => setsegFilter("");
  const clearSkuTypeFilter = () => setskuTypeFilter("");

  useEffect(() => {
    setTermOptions(getUniqueValues(filteredData, "TermReadable"));
    setBcOptions(getUniqueValues(filteredData, "BillingCycle"));
    setSegOptions(getUniqueValues(filteredData, "Segment"));
    setSkuTypeOptions(getUniqueValues(filteredData, "skuType"));
  }, [filteredData]);

  useEffect(() => {
    console.log(isAuthenticated);
    if (isAuthenticated) {
      console.log("Authenticated");
      const accounts = pca.getAllAccounts();
      console.log(accounts);
      if (accounts.length > 0) {
        setEmail(accounts[0].username);
        const request = {
          scopes: ["user.read"], // Adjust scopes as necessary
          account: accounts[0],
        };

        const customApiScopes = [
          "api://itsavvyllc.onmicrosoft.com/cspAPI/api-scope",
        ]; // Scope for your custom API

        const customApiTokenRequest = {
          scopes: customApiScopes,
          account: pca.getAllAccounts()[0], // Assuming the user is signed in
        };

        pca
          .acquireTokenSilent(customApiTokenRequest)
          .then((response) => {
            // Use the token to call your custom API
            const customApiToken = response.accessToken;
            console.log("Token for Custom API:", customApiToken);
            setToken(response.accessToken);
          })
          .catch((error) => {
            console.error(error);
          });

        pca.acquireTokenSilent(request).then((response) => {
          // Use the token from response.accessToken for your API calls
          console.log("Token acquired silently:", response.idToken);
        });
      }
    }
  }, [isAuthenticated]); // Dependency array ensures this effect runs when isAuthenticated changes

  // Axios instance or API call setup...

  useEffect(() => {
    // Load saved opportunities from local storage when component mounts
    const savedOpportunities = JSON.parse(
      localStorage.getItem("opportunities")
    );
    if (savedOpportunities) {
      setSavedOpportunities(savedOpportunities);
    }
  }, []);

  const handleLoadOpportunity = (opportunityName) => {
    // Example logic to load the opportunity and its items into the cart
    // Here, we assume that you have access to the opportunity's items
    // You might need to fetch the items associated with the opportunityName from your data source

    // Find the opportunity in your saved opportunities list
    const opportunity = savedOpportunities.find(
      (opportunity) => opportunity.name === opportunityName
    );

    if (opportunity) {
      // Extract the cart items from the opportunity object
      const cartItemsFromOpportunity = opportunity.cartItems;

      // Update the cartItems state to include the items from the loaded opportunity
      setCartItems(cartItemsFromOpportunity);
    } else {
      console.error(`Opportunity '${opportunityName}' not found.`);
      // Handle the case where the opportunity is not found
      // For example, show an error message to the user
    }
  };

  useEffect(() => {
    // Save saved opportunities to local storage whenever it changes
    localStorage.setItem("opportunities", JSON.stringify(savedOpportunities));
  }, [savedOpportunities]);

  useEffect(() => {
    // Load cart from local storage when component mounts
    const savedCart = localStorage.getItem(opportunityName); // Load cart for the current opportunity
    if (savedCart) {
      setCartItems(JSON.parse(savedCart));
    }
  }, [opportunityName]);

  useEffect(() => {
    // Save cart to local storage whenever it changes
    localStorage.setItem(opportunityName, JSON.stringify(cartItems)); // Save cart for the current opportunity
  }, [cartItems, opportunityName]);

  useEffect(() => {
    if (token) {
      // Ensure token is available
      console.log(token);
      const api = axios.create({
        baseURL: "https://af-csp-ps-cus.azurewebsites.net/api",
        headers: {
          Authorization: `Bearer ${token}`, // Dynamically set the Authorization header
        },
      });

      const pythonapi = axios.create({
        baseURL: "https://csppythonprotectedfunctions.azurewebsites.net/api",
        headers: {
          Authorization: `Bearer ${token}`, // Dynamically set the Authorization header
        },
      });

      const cache = {};

      const cacheKey = "apiData";
      setIsLoading(true);
      if (cache[cacheKey]) {
        setData(cache[cacheKey]);
        setFilteredData(cache[cacheKey]);
      } else {
        pythonapi
          .get(
            "/getNCESKU?code=Fof-gCUi1eUD92IlTYq9ce8IdDvTCQaxlQKyNcqBzKePAzFu0LDOEQ%3D%3D"
          )
          .then((response) => {
            if (response.data.length > 0) {
              const dataWithIdAndTerm = response.data.map((item, index) => ({
                ...item,
                quantity: 1,
                id: item.RowKey || index,
                TermReadable:
                  item.TERM === "P1Y"
                    ? "Annual"
                    : item.TERM === "P1M"
                    ? "Monthly"
                    : item.TERM === "P3Y"
                    ? "3 Year"
                    : item.TERM === "OneTime"
                    ? "OneTime"
                    : "Unknown",

                // 'Product Name': item.Name, // Assuming 'Name' is the correct key for the product name
              }));
              //
              setData(dataWithIdAndTerm);
              setFilteredData(dataWithIdAndTerm);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
            setIsLoading(false);
          });
      }
      // Move your API calls here or update them to use the `api` instance...
    }
  }, [token]);

  const handleToggleSavedOpportunities = () => {
    setShowSavedOpportunities(
      (prevShowSavedOpportunities) => !prevShowSavedOpportunities
    );
  };

  const clearCart = () => {
    setCartItems([]);
  };
  const handleSearchInput = (event) => {
    setSearchInput(event.target.value); // Update the input value as user types
  };

  // Handler for text field blur event
  const handleSearchBlur = () => {
    setCommitSearch(searchInput); // Commit the value on blur
  };

  function CustomToolbar({
    onFilterChange,
    onBcFilterChange,
    onSegFilterChange,
    onSkuTypeFilterChange,
    termValue,
    bcValue,
    segValue,
    skuTypeValue,
    clearTermFilter,
    clearBcFilter,
    clearSegFilter,
    clearSkuTypeFilter,
  }) {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />

        {/* Term Filter */}
        <TextField
          select
          value={termValue}
          onChange={(e) => onFilterChange(e.target.value)}
          variant="outlined"
          style={{ width: 180, margin: "0 10px" }}
          SelectProps={{ displayEmpty: true }}
          InputProps={{
            endAdornment: termValue && (
              <IconButton size="small" onClick={clearTermFilter}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        >
          <MenuItem value="">All Commit Terms</MenuItem>
          {termOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        {/* Billing Cycle Filter */}
        <TextField
          select
          value={bcValue}
          onChange={(e) => onBcFilterChange(e.target.value)}
          variant="outlined"
          style={{ width: 180, margin: "0 10px" }}
          SelectProps={{ displayEmpty: true }}
          InputProps={{
            endAdornment: bcValue && (
              <IconButton size="small" onClick={clearBcFilter}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        >
          <MenuItem value="">All Billing Cycles</MenuItem>
          {bcOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        {/* Segment Filter */}
        <TextField
          select
          value={segValue}
          onChange={(e) => onSegFilterChange(e.target.value)}
          variant="outlined"
          style={{ width: 180, margin: "0 10px" }}
          SelectProps={{ displayEmpty: true }}
          InputProps={{
            endAdornment: segValue && (
              <IconButton size="small" onClick={clearSegFilter}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        >
          <MenuItem value="">All Segments</MenuItem>
          {segOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        {/* SKU Type Filter */}
        <TextField
          select
          value={skuTypeValue}
          onChange={(e) => onSkuTypeFilterChange(e.target.value)}
          variant="outlined"
          style={{ width: 180, margin: "0 10px" }}
          SelectProps={{ displayEmpty: true }}
          InputProps={{
            endAdornment: skuTypeValue && (
              <IconButton size="small" onClick={clearSkuTypeFilter}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        >
          <MenuItem value="">All SKU Types</MenuItem>
          {skuTypeOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <Button onClick={clearFilters} color="primary" variant="contained">
          Clear Filters
        </Button>
      </GridToolbarContainer>
    );
  }

  //console.log(token)

  const incrementQuantity = (itemId) => {
    setCartItems(
      cartItems.map((item) =>
        item.id === itemId ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const decrementQuantity = (itemId) => {
    setCartItems(
      cartItems
        .map((item) =>
          item.id === itemId
            ? { ...item, quantity: Math.max(0, item.quantity - 1) }
            : item
        )
        .filter((item) => item.quantity > 0)
    ); // This also removes the item if quantity is 0
  };

  const api = axios.create({
    baseURL: "https://af-csp-ps-cus.azurewebsites.net/api",
    headers: {
      Authorization: `Bearer ${token}`, // Set the Authorization header
    },
  });

  // Now you can use the instance without specifying the token each time

  useEffect(() => {
    let filtered = data.filter((item) =>
      Object.keys(item).some((key) =>
        String(item[key]).toLowerCase().includes(commitSearch.toLowerCase())
      )
    );

    if (termFilter) {
      filtered = filtered.filter((item) => item.TermReadable === termFilter);
    }
    if (bcFilter) {
      filtered = filtered.filter((item) => item["BillingCycle"] === bcFilter);
    }
    if (segFilter) {
      filtered = filtered.filter((item) => item["Segment"] === segFilter);
    }
    if (skuTypeFilter) {
      filtered = filtered.filter((item) => item["skuType"] === skuTypeFilter);
    }

    setFilteredData(filtered);
  }, [commitSearch, data, termFilter, bcFilter, segFilter, skuTypeFilter]);

  const handleRowClick = (params) => {
    setModalProduct(params.row); // Update the selected product for the modal
    setIsModalOpen(true); // Open the modal
  };

  const columns = [
    {
      field: "ProductName",
      headerName: "Product Name",
      width: 300,
      renderCell: (params) => {
        // Log the params to see its structure

        const isPromoActive =
          params.row.promo && params.row.promo.trim() !== "None";
        return (
          <div
            style={{
              cursor: "pointer",
              color: isPromoActive ? "orange" : "blue", // Conditional color based on promo status
              textDecoration: "underline",
              whiteSpace: "normal",
              wordWrap: "break-word",
              lineHeight: "normal",
              fontWeight: "bold",
            }}
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleRowClick(params);
            }}
            title={params.row.Description} // Show product description on hover
          >
            {params.value}
          </div>
        );
      },
    },

    { field: "TermReadable", headerName: "TERM", width: 90 },
    { field: "BillingCycle", headerName: "Billing", width: 90 },

    { field: "SKU", headerName: "SKU", width: 200 },
    { field: "Price", headerName: "MSRP", width: 75 },
    // { field: 'Cost', headerName: 'Cost', width: 75 },
    { field: "Segment", headerName: "Segment", width: 125, hide: true },
    { field: "skuType", headerName: "Sku Type", width: 125, hide: true },

    // Add or adjust columns as needed
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select row after clicking
          const itemToAdd = data.find((item) => item.id === params.id);
          handleAddToCart(itemToAdd);
        };

        return <Button onClick={onClick}>Add to Cart</Button>;
      },
    },

    // { field: 'id', headerName: 'ID', width: 90, hide: true }
  ];

  const handleAddAllToCart = () => {
    const itemsToAdd = data.filter((product) =>
      selectedProducts.includes(product.id)
    );
    const newCartItems = [...cartItems];
    console.log(cartItems);
    console.log(itemsToAdd);
    console.log(selectedProducts);

    itemsToAdd.forEach((item) => {
      const isItemInCart = newCartItems.some(
        (cartItem) => cartItem.id === item.id
      );
      if (!isItemInCart) {
        newCartItems.push(item);
      } else {
        console.log("Some items are already in the cart.");
      }
    });

    setCartItems(newCartItems);
    setShowCart(true);
  };

  const handleAddToCart = (itemToAdd) => {
    const itemInCart = cartItems.find((item) => item.id === itemToAdd.id);
    if (itemInCart) {
      setCartItems(
        cartItems.map((item) =>
          item.id === itemToAdd.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      );
    } else {
      setCartItems([...cartItems, { ...itemToAdd, quantity: 1 }]);
    }
  };

  const saveCartToOpportunity = (opportunityName) => {
    // Implement logic to save the cart items under the specified opportunity
    // For example, you can update the savedOpportunities state with the new opportunity
    // You can structure the savedOpportunities state as an array of objects with opportunity names and corresponding cart items
    // Here's a basic example:
    const updatedOpportunities = [...savedOpportunities];
    const existingOpportunityIndex = updatedOpportunities.findIndex(
      (opp) => opp.name === opportunityName
    );
    if (existingOpportunityIndex !== -1) {
      // If opportunity already exists, update its cart items
      updatedOpportunities[existingOpportunityIndex].cartItems = cartItems;
    } else {
      // If opportunity doesn't exist, add it to the list
      updatedOpportunities.push({
        name: opportunityName,
        cartItems: cartItems,
      });
    }
    setSavedOpportunities(updatedOpportunities);
  };

  const handleOpenCart = () => {
    const selectedItems = data.filter((product) =>
      selectedProducts.includes(product.id)
    );
    setShowCart(true); // Show the cart with the selected items
  };

  const handleSendCart = () => {
    if (!email) {
      alert("Please enter your email address.");
      return;
    }
    setIsSendingCart(true);
    const url1 =
      "https://fa-cus-afa-gdap-02.azurewebsites.net/api/sendEmailNCESkus?code=1Jj11RvYgwr6CTj5t9pczHvUsEdmXvnfc2cqyfaIQx321cUaFyAXwQ==&clientId=apim-fa-cus-afa-gdap-02-apim";
    console.log("Cart items:", cartItems);
    console.log(cartItems);
    axios
      .post(url1, {
        email: email, // Include the email in the POST request
        cartItems: cartItems,
      })
      .then((response) => {
        console.log("Cart sent successfully:", response);
        // Handle success response, such as clearing the cart or showing a success message
      })
      .catch((error) => {
        console.error("Error sending cart:", error);
        // Handle error response, such as showing an error message
      })
      .finally(() => {
        setIsSendingCart(false); // Stop loading regardless of the outcome
      });
  };

  const handleSendToSavvyPortal = (accountNumber, cartItems, token) => {
    // Implement the hook logic here
    console.log("Account Number:", accountNumber);
    console.log("Cart Items:", cartItems);
    // Add your hook logic here
  };

  if (!isAuthenticated) {
    return <SignInButton />;
  }

  return (
    <Container>
      <CssBaseline />
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1 1 30%", marginRight: "20px" }}>
          {showSavedOpportunities && (
            <SavedOpportunities
              opportunities={savedOpportunities}
              onLoadOpportunity={handleLoadOpportunity}
              show={showSavedOpportunities}
            />
          )}
        </div>
        <div style={{ flex: "3 1 auto" }}>
          <SearchTextField onFinish={(text) => setCommitSearch(text)} />

          <Button
            onClick={handleOpenCart}
            variant="contained"
            style={{ marginBottom: "20px" }}
          >
            View Cart
          </Button>
          <Button
            onClick={handleToggleSavedOpportunities}
            variant="contained"
            style={{ marginBottom: "20px" }}
          >
            {showSavedOpportunities
              ? "Hide Saved Opportunities"
              : "Show Saved Opportunities"}
          </Button>
          <Button
            onClick={handleAddAllToCart}
            variant="contained"
            style={{ marginBottom: "20px", marginLeft: "8px" }}
          >
            Add Selected to Cart
          </Button>
          <Button
            onClick={handleSendCart}
            variant="contained"
            style={{ marginBottom: "20px", marginLeft: "8px" }}
            disabled={isSendingCart}
          >
            {isSendingCart ? <CircularProgress size={24} /> : "Send Cart"}
          </Button>
          <TextField
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            style={{ marginBottom: "20px", marginRight: "8px" }}
          />

          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <ItsLoadingSpinner />
            </div>
          ) : (
            <DataGrid
              rows={filteredData}
              columns={columns}
              pageSize={10}
              checkboxSelection
              disableSelectionOnClick={false}
              components={{
                Toolbar: CustomToolbar,
              }}
              componentsProps={{
                toolbar: {
                  onFilterChange: setTermFilter,
                  onBcFilterChange: setbcFilter,
                  onSegFilterChange: setsegFilter,
                  onSkuTypeFilterChange: setskuTypeFilter,
                  termValue: termFilter,
                  bcValue: bcFilter,
                  segValue: segFilter,
                  skuTypeValue: skuTypeFilter,
                  clearTermFilter: clearTermFilter,
                  clearBcFilter: clearBcFilter,
                  clearSegFilter: clearSegFilter,
                  clearSkuTypeFilter: clearSkuTypeFilter,
                },
              }}
              onRowSelectionModelChange={(newSelectionModel) => {
                setSelectedProducts(newSelectionModel);
              }}
              selectionModel={selectedProducts}
            />
          )}
        </div>
      </div>
      <Cart
        items={cartItems}
        onClose={() => setShowCart(false)}
        show={showCart}
        clearCart={clearCart}
        incrementQuantity={incrementQuantity}
        decrementQuantity={decrementQuantity}
        setCartItems={setCartItems}
        onSaveCartToOpportunity={saveCartToOpportunity}
        token={token}
      />
      <ProductDetailsModal
        product={modalProduct}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAddToCart={(productToAdd) => handleAddToCart(productToAdd)}
      />
    </Container>
  );
};

function App() {
  return (
    <MsalProvider instance={pca}>
      <div className="App">
        <AuthenticatedApp />
      </div>
    </MsalProvider>
  );
}

export default App;
